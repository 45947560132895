import React, { useRef, FormEvent } from 'react';
import { useState } from 'react';
import Container from './components/Container';
import { callCloudFunction } from './services/functions';

function App() {
	const emailRef = useRef<HTMLInputElement>(null);
	const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			setError(null);
      setSuccess(null);
			setLoading(true);
      const promoEmailValidator = await callCloudFunction('promoEmailValidator');
      const result = await promoEmailValidator(emailRef.current?.value);
      if (result.data) {
        if (result.data.error) {
          setError(result.data.error)
        } else {
          setSuccess(result.data.success);
        }
      }
		} catch (error) {
			setError('503 - Database Connection Error');
		}
		setLoading(false);
	};

	return (
		<Container>
			<form onSubmit={handleSubmit} className='border-t-2 border-gray-200 px-8'>
				<div className='mt-4'>
					<div className='text-center font-bold text-2xl mb-2'>Summer Promotion!</div>
					<div className="text-center">Get <span className="font-bold">2 weeks</span> of <span className="font-bold">FREE PREMIUM</span> access of Mussila Music School, <span className="font-bold">no credit card required.</span></div>
				</div>
        <div
					hidden={error ? false : true}
					className='my-4 py-2 bg-pink-1 bg-opacity-75 rounded-lg font-medium text-center text-white'
				>
					{error}
				</div>
        <div
					hidden={success ? false : true}
					className='my-4 py-2 bg-green-2 bg-opacity-75 rounded-lg font-medium text-center text-white'
				>
					{success}
				</div>
				<div className={error || success ? '' : 'mt-4'}>
					<input
						placeholder='Enter your email'
						disabled={loading}
            className={`focus-within:ring-pink-1 focus-within:border-orange-1 placeholder-gray-400 placeholder-opacity-70 border border-gray-200 px-3 py-3 w-full rounded-xl`}
						required
						type='email'
						name='email'
						ref={emailRef}
					/>
				</div>
				<button
					type='submit'
					disabled={loading}
					className={`mt-8 py-4 w-full focus:outline-none rounded-lg text-center text-white text-lg bg-gradient-to-r ${loading ? 'opacity-50' : ''} from-pink-1 to-orange-1`}
				>
					Confirm
				</button>
			</form>
		</Container>
	);
}

export default App;
