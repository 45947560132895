import React from 'react';
import logo from '../assets/images/mussila_logo.svg';
import mascot_love from '../assets/images/mussila_mascot_love.svg';
//import mussila_qr from './assets/images/mussila_qr.svg';
import apple from '../assets/images/appstore_badge.png';
import googleplay from '../assets/images/googleplay_badge.png';

// Needs a better name, this container is intended for Login / Register and Error Page
const Container: React.FC = ({ children }) => {
	return (
		<div className='min-h-screen bg-blue-2 flex flex-col items-center justify-center shadow-md relative'>
			<div className='bg-white pb-6 rounded-lg z-10 relative'>
				<div className='m-4 flex justify-center px-16 py-2'>
					<img alt='mussila logo' src={logo} className='h-20' />
				</div>
				{children}
			</div>
			<div className='bg-white m-3 rounded-lg z-10'>
				<div className='m-1 flex justify-center px-16 py-2'>
					<a href="https://mussila.app.link/Mussila" className="transform hover:scale-110"><img alt='mussila logo' src={apple} className='h-20 mr-8' /></a>
					<a href="https://mussila.app.link/Mussila" className="transform hover:scale-110"><img alt='mussila logo' src={googleplay} className='h-20 ml-7' /></a>
				</div>
			</div>
			<img alt='mussila mascot' src={mascot_love} className='absolute top-10 h-96 z-0' />
		</div>
	);
};

export default Container;
